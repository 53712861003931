@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 0;
}

.fimanager:before {
      display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    display: block;
}

.flaticon-001-anatomy:before { content: "\f100"; }
.flaticon-002-aesthetic:before { content: "\f101"; }
.flaticon-003-anatomy:before { content: "\f102"; }
.flaticon-004-beauty:before { content: "\f103"; }
.flaticon-005-asymmetry:before { content: "\f104"; }
.flaticon-006-breast:before { content: "\f105"; }
.flaticon-007-aesthetic:before { content: "\f106"; }
.flaticon-008-abdominoplasty:before { content: "\f107"; }
.flaticon-009-anatomy:before { content: "\f108"; }
.flaticon-010-beauty:before { content: "\f109"; }
.flaticon-011-beauty:before { content: "\f10a"; }
.flaticon-012-beauty:before { content: "\f10b"; }
.flaticon-013-anatomy:before { content: "\f10c"; }
.flaticon-014-anatomy:before { content: "\f10d"; }
.flaticon-015-abdomen:before { content: "\f10e"; }
.flaticon-016-belly:before { content: "\f10f"; }
.flaticon-017-aesthetic:before { content: "\f110"; }
.flaticon-018-beauty:before { content: "\f111"; }
.flaticon-019-aesthetic:before { content: "\f112"; }
.flaticon-020-beauty:before { content: "\f113"; }
.flaticon-021-anatomy:before { content: "\f114"; }
.flaticon-022-arm:before { content: "\f115"; }
.flaticon-023-beauty:before { content: "\f116"; }
.flaticon-024-abdominoplasty:before { content: "\f117"; }
.flaticon-025-aesthetic:before { content: "\f118"; }
.flaticon-026-anatomy:before { content: "\f119"; }
.flaticon-027-beauty:before { content: "\f11a"; }
.flaticon-028-beauty:before { content: "\f11b"; }
.flaticon-029-beauty:before { content: "\f11c"; }
.flaticon-030-belly:before { content: "\f11d"; }
.flaticon-031-anatomy:before { content: "\f11e"; }
.flaticon-032-anatomy:before { content: "\f11f"; }
.flaticon-033-beauty:before { content: "\f120"; }
.flaticon-034-healthcare-and-medical:before { content: "\f121"; }
.flaticon-035-beauty:before { content: "\f122"; }
.flaticon-036-beauty:before { content: "\f123"; }
.flaticon-037-belly:before { content: "\f124"; }
.flaticon-038-beauty:before { content: "\f125"; }
.flaticon-039-beauty:before { content: "\f126"; }
.flaticon-040-anatomy:before { content: "\f127"; }
.flaticon-041-anatomy:before { content: "\f128"; }
.flaticon-042-beauty:before { content: "\f129"; }
.flaticon-043-beauty:before { content: "\f12a"; }
.flaticon-044-aesthetic:before { content: "\f12b"; }
.flaticon-045-belly:before { content: "\f12c"; }
.flaticon-046-breast:before { content: "\f12d"; }
.flaticon-047-eye:before { content: "\f12e"; }
.flaticon-048-beauty:before { content: "\f12f"; }
.flaticon-049-abdominoplasty:before { content: "\f130"; }
.flaticon-050-belly:before { content: "\f131"; }